<template>
  <div class="app-container">
    <div class="content_select">
      <div>
        <qzf-search-period
          v-model:period="listQuery.period"
          @success="getList"
        ></qzf-search-period>
        <selectuser
          code="bookkeeping"
          v-model:userId="listQuery.userId"
          @success="getList()"
          style="margin-left: 5px;width: 150px;"
        ></selectuser>
        <!-- <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        > -->
      </div>

      <div>
        <el-button
          @click="daochu()"
          type="primary"
          size="small"
        >
          <el-icon><FolderOpened /></el-icon> <span>导出</span>
        </el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        :height="contentStyleObj"
        @selection-change="handleSelectionChange"
        stripe
        show-summary  
        :summary-method="getSummaries"  
        ref="tableScroll"
        id="tableLazyLoad"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column
          label="员工"
          align="center"
          width="80"
          fixed="left"
          prop="cnName"
        >
        </el-table-column>
        <el-table-column
          prop="deptName"
          align="center"
          label="部门"
          min-width="100"
          show-overflow-tooltip
          fixed="left"
        >
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="负责账簿"
          width="65"
          align="center"
        >
          <el-table-column
            prop="sumCount"
            label="总数"
            sortable 
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="zzs"
            label="分布情况"
            align="center"
            min-width="160"
          >
            <el-table-column
              prop="xgmCount"
              label="小微企业"
              align="right"
              min-width="60"
            >
              <template #header>
                <div class="tag-name-copy">
                  <div class="ellipsis name-text ">
                    <div style="width: 75px;">
                      <span
                        class="xgm tag-item"
                      >小</span>
                    </div>
                  </div>
                </div>
              </template>
              <!-- <template #default="scope">
                {{ scope.row.xgmCount }}
              </template> -->
            </el-table-column>
            <el-table-column
              prop="ybCount"
              label="一般纳税人"
              align="right"
              min-width="60"
            >
              <template #header>
                <div class="tag-name-copy">
                  <div class="ellipsis name-text ">
                    <div style="width: 75px;">
                      <span
                        class="yb tag-item"
                      >般</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="申报进度"
          width="65"
          align="center"
        >
          <el-table-column
            prop="sbwksCount"
            label="未开始"
            sortable
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="sbjxzCount"
            label="进行中"
            sortable
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="sbywcCount"
            label="已完成"
            sortable
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="sbwxsbCount"
            label="无需申报"
            sortable
            align="right"
            min-width="100"
          />
          <el-table-column
            prop="sbwcl"
            label="完成率"
            sortable
            align="right"
            min-width="90"
          >
            <template #default="scope">
              {{ (scope.row.sbwcl*100).toFixed(2) }}%
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="记账进度"
          width="65"
          align="center"
        >
          <el-table-column
            prop="jzwksCount"
            label="未开始"
            sortable
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="jzjxzCount"
            label="进行中"
            sortable
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="jzywcCount"
            label="已完成"
            sortable
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="jzwcl"
            label="完成率"
            sortable
            align="right"
            min-width="90"
          >
            <template #default="scope">
              {{ (scope.row.jzwcl*100).toFixed(2) }}%
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="扣款进度"
          width="65"
          align="center"
        >
          <el-table-column
            prop="kkwksCount"
            label="未开始"
            sortable
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="kkjxzCount"
            label="进行中"
            sortable
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="kkywcCount"
            label="已完成"
            sortable
            align="right"
            min-width="90"
          />
          <el-table-column
            prop="kkwxkkCount"
            label="无需扣款"
            sortable
            align="right"
            min-width="100"
          />
          <el-table-column
            prop="kkwcl"
            label="完成率"
            sortable
            align="right"
            min-width="90"
          >
            <template #default="scope">
              {{ (scope.row.kkwcl*100).toFixed(2) }}%
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="检查进度"
          width="65"
          align="center"
        >
          <el-table-column
            prop="jcwksCount"
            label="未开始"
            align="right"
            sortable
            min-width="90"
          />
          <el-table-column
            prop="jcjxzCount"
            label="进行中"
            align="right"
            sortable
            min-width="90"
          />
          <el-table-column
            prop="jcywcCount"
            label="已完成"
            align="right"
            sortable
            min-width="90"
          />
          <el-table-column
            prop="jcwcl"
            label="完成率"
            align="right"
            sortable
            min-width="90"
          >
            <template #default="scope">
              {{ (scope.row.jcwcl*100).toFixed(2) }}%
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </div>
</template>

<script>
import { currentAccountPeriod } from "@/utils";
import { userOrgList,exportUserOrgList } from "@/api/maintain";

export default {
  name: "progressTracking",
  components: { },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        period: currentAccountPeriod(),
        userId: [],
      },
      loading: false,
      list: [],
      total: 0,
      ids: [],
      selects: [],
      tableData: [],
      totalPage: 0,
      pageSize: 50,
      currentPage: 1,
      gridData: [], //负责人数据
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(210);
    this.getList();
  },
  mounted() {
    // this.getList();
  },

  methods: {
    getSummaries({ columns, data }) {  
      const sums = [];  
      columns.forEach((column, index) => {  
        if (index === 0) {  
          sums[index] = '合计'; // 为员工和部门列设置空字符串，即不显示合计  
          return;  
        }  
        if (column.label === '员工' || column.label === '部门' || column.label === '完成率') { // 假设员工在第一列，部门在第二列  
          sums[index] = ' '; // 为员工和部门列设置空字符串，即不显示合计  
          return;  
        }
        // 对于其他需要计算的列（如总数、未开始、进行中、已完成等），进行合计计算  
        const values = data.map(item => Number(item[column.property]));  
        if (!values.every(value => isNaN(value))) {  
          sums[index] = values.reduce((prev, curr) => {  
            const value = Number(curr);  
            if (!isNaN(value)) {  
              return prev + curr;  
            } else {  
              return prev;  
            }  
          }, 0);  
          sums[index] += ' '; // 添加空格以便和其他值对齐（可选）  
        } else {  
          sums[index] = ' '; // 或者其他表示不适用的值  
        }  
      });  
    
      return sums;  
    },
    // 重置
    cancel() {
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        page: 1,
        limit: originLimit,
        userId: [],
        period: currentAccountPeriod(),
      };
      this.getList();
    },
    getList() {
      this.loading = true;
      userOrgList(this.listQuery).then((res) => {
        console.log(res);
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e){
      this.ids = [];
      e.map(item=>{
        this.ids.push(item.id)
      })
      console.log(this.ids);
    },
    daochu(){
      let param = {
        ids: this.ids,
        query: this.listQuery,
      };
      exportUserOrgList(param).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content_select{
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.tag-name-copy {
  display: flex;
  align-items: center;
  .name-text {
    flex: 1;
    width: 0;
    display: flex;

    .tag-item {
      padding: 0 1px;
    }
    .xgm {
      border: 1px solid var(--themeColor, #17a2b8);
      background: var(--themeColorPlain);
      color: var(--themeColor, #17a2b8);
    }
    .yb {
      border: 1px solid #ff7a00;
      background: $color-danger-plain;
      color: #ff7a00;
    }
  }
  .copy-name {
    color:var(--themeColor,#17a2b8);
    cursor: pointer;
    & + div,
    & + i {
      margin-left: 8px;
    }
  }
}
</style>